import React from "react"
import {Helmet} from "react-helmet"
import '../assets/css/main.css'
import StaticHeader from "../components/StaticHeader/StaticHeader"
import Footer from '../components/Footer/Footer'
import TableRow from "../components/TableRow/TableRow"
import NavBar from '../components/NavBar/NavBar'

const TableClasses = "max-w-3xl mx-auto w-full mt-10 mb-10"

const TechReqs = (props) => (
  <>
    <Helmet>
      <title>Foreign Affairs Mediakit - Technical Requirements</title>
    </Helmet>
    <NavBar home={false} />
    <StaticHeader
      title="Technical Requirements"
      subtitle=""
      intro='<p>For further questions about advertising specs or the materials submission process, please contact your account manager or email <a href="mailto:faadvertising@cfr.org">FAadvertising@cfr.org</a>.</p>'
    />
    <div className="max-w-screen-xl mx-auto px-5">
      <h3 className="max-w-3xl mx-auto pt-8 p-0x neue-haas font-bold">Print Specifications</h3>
      
      <TableRow 
        headings=""
        rows={[
          [
            'Full Page|font-bold, pl-0|',
            '7" x 10"'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />

      <TableRow 
        headings={[
          "Full Bleeds|text-left|2"
        ]}
        rows={[
          [
            'Single Page',
            '7 ¼" x 10 ¼"'
          ],[
            'Spread',
            '14 ¼" x 10 ¼"'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />
      
      <TableRow 
        headings={[
          "Non Bleeds|text-left|2"
        ]}
        rows={[
          [
            'Single Page',
            '6 ¼" x 9"'
          ],[
            'Half Page Horizontal',
            '5 ¾" x 3 ⅞"'
          ],[
            'Half Page Vertical',
            '2 ¾" x 8"'
          ],[
            'Quarter Page',
            '2 ¾" x 3 ⅞"'
          ],[
            'Gutter Bleed Spread',
            '13 ¼" x 9"'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />

      <TableRow 
        headings={[
          "Safety|text-left"
        ]}
        rows={[
          [
            'Keep live matter from trim ⅜" all sides'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />

      <TableRow 
        headings={[
          "Bindery Trim|text-left"
        ]}
        rows={[
          [
            '3/16" top and bottom, ⅛" outside, ⅛" gutter grind off'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />

      <TableRow 
        headings={[
          "File Type|text-left"
        ]}
        rows={[
          [
            'PDF, 300dpi'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />
      
      <TableRow 
        headings={[
          "Color|text-left"
        ]}
        rows={[
          [
            'CMYK (no RGB)'
          ]
        ]}
        classes={TableClasses}
        footer=""
      />
    </div>
    <Footer />
  </>
)

export default TechReqs
